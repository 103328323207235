import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import RingLoading from 'components/lottie/RingLoading'
import { Card } from 'react-bootstrap'
import { Divider, List, ListItem, ListItemAvatar, ListItemText, Rating, Stack, Typography } from '@mui/material'
import SingleProductTabs from './helper/SingleProductTabs'
import { FaCheck, FaCircle } from 'react-icons/fa'
import { CardLongItem } from 'components/cards/Card'
import ViewCartRedierctBox from 'v2/src/components/common/ViewCartBox'
import TestImage from '../../../../../assets/images/img-1.jpg'
import { useGetProductQuery, useLazyGetProductRatingsQuery } from 'v2/src/lib/services/marketplace/nHProductsApi'
import { useAddCartItemsMutation, useGetCartItemsQuery, useRemoveCartItemMutation, useUpdateCartItemQuantityMutation } from 'v2/src/lib/services/marketplace/nHCartApi'
import { toast } from 'react-toastify'
import useDebounce from 'hooks/useDebounce'
import ProductImagePreviewModel from './helper/ProductImagePreviewModel'
import { constant } from './productConstant'
import AddToCartButton from 'v2/src/components/common/AddToCartButton'
import { BasePriceIfHasDiscount, DiscountedButton } from './common/ProductPriceComponents'
import { ProductCardRatings } from './helper/ProductsCardHelper'

const { SIDE_PANEL } = constant

// list of details
const ListItemWithIcon = ({ title, content, iconColor = '#0dc8de' }) => (
  <ListItem alignItems="flex-start" sx={{ gap: 1.5, p: 0 }}>
    <ListItemAvatar
      sx={{
        width: 32,
        height: 32,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        backgroundColor: '#e6f9fc',
        minWidth: 0,
      }}
    >
      <FaCheck style={{ color: iconColor, width: 16, height: 16 }} />
    </ListItemAvatar>
    <ListItemText
      primary={title}
      sx={{
        '& .MuiListItemText-primary': { mb: 1, fontSize: 20, fontWeight: 500 },
      }}
      secondary={
        <Typography component="span" fontSize={15} sx={{ color: 'text.primary', display: 'inline' }}>
          {content}
        </Typography>
      }
    />
  </ListItem>
)

export default function MarketPlaceSingleProduct() {
  const { t: patient_marketplace } = useTranslation('patient_marketplace')
  const { t: patient_common } = useTranslation('patient_common')
  const { id: productId } = useParams()
  const params = new URLSearchParams()
  params.append('relations[]', 'images')
  params.append('relations[]', 'brand')
  params.append('relations[]', 'variants')
  params.append('relations[]', 'dosageForm')
  params.append('relations[]', 'details')
  params.append('relations[]', 'ratings')

  // cart APis for add,update,get and delete.
  const [addCartItems] = useAddCartItemsMutation()
  const [updateCartItemQuantity] = useUpdateCartItemQuantityMutation()
  const [removeCartItem] = useRemoveCartItemMutation()
  const { data: cartItems } = useGetCartItemsQuery({ page: 1, per_page: 4 }, { refetchOnMountOrArgChange: true })

  const { data: singleProduct, isLoading } = useGetProductQuery({ id: productId, params })
  const [getProductRatings, { data: productRatings }] = useLazyGetProductRatingsQuery()

  const [currentProduct, setCurrentProduct] = useState({}) // current displayed product
  const [activeProducts, setActiveProducts] = useState([]) // active products in cart
  const [selectedVariant, setSelectedVariant] = useState() // current selected variants
  const [selectedVariantItem, setSelectedVariantItem] = useState({}) // current selected variants
  const [filteredSidePanel, setFilteredSidePanel] = useState([]) // panels for details
  const [matchedcartItemQuantity, setMatchedcartItemQuantity] = useState(0) // matched cart item's quantity
  const [searchReviewText, setSearchReviewText] = useState()
  const [isPreviewOpen, setIsPreviewOpen] = useState(false)

  const debouncedSearchText = useDebounce(searchReviewText, 300) // Debounce searchText by 300 ms

  const { items } = cartItems?.data || [] // Total Items included in cart.

  // Get and set in curent Displayed product from api response
  useEffect(() => {
    if (singleProduct && singleProduct.data) {
      setCurrentProduct(singleProduct.data) // Set the fetched data into the currentProduct state
      if (singleProduct.data?.variants?.length > 0) {
        setSelectedVariant(0)
        setSelectedVariantItem(singleProduct?.data?.variants[0])
      }
    }
  }, [singleProduct])

  // Filter the tabs once and store in the state
  useEffect(() => {
    if (currentProduct?.details?.length) {
      setFilteredSidePanel(currentProduct.details.filter((tab) => tab.display_type === SIDE_PANEL))
    }
  }, [currentProduct])

  // trigger with cart Items
  useEffect(() => {
    items?.map((item) =>
      setActiveProducts((prev) => {
        if (prev.includes(item.product.id)) {
          return prev
        }
        return [...prev, item.product.id]
      })
    )
  }, [cartItems])

  useEffect(() => {
    if (cartItems?.data && currentProduct) {
      const cartProduct = items.find((item) => item.product.id === currentProduct?.id && item?.variant?.id === selectedVariantItem?.id)
      const cartQuantity = cartProduct ? cartProduct?.quantity : 0
      setMatchedcartItemQuantity(cartQuantity)
    }
  }, [cartItems, currentProduct, selectedVariantItem])

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        if (productId) {
          params.append('product_id', productId)
          if (debouncedSearchText) params.append('search', debouncedSearchText)
          await getProductRatings(params).unwrap()
        }
      } catch (error) {
        toast.error(error?.data?.message)
      }
    }

    fetchProducts()
  }, [productId, debouncedSearchText])

  // Handle adding or updating product quantity in cart
  const handleAddToCart = async (product) => {
    const params = { product_id: product.id, quantity: 1 }

    // Check and send it if product has variants or not.
    if (product.variants.length) {
      const firstVariantId = product.variants[selectedVariant].id
      params.variant_id = firstVariantId
    }

    const { product_id, quantity, variant_id } = params
    try {
      const payload = { product_id, quantity, variant_id }
      const response = await addCartItems(payload).unwrap()
      toast.success(response?.message || `${product.name} added to cart!`)
    } catch (error) {
      toast.error(error?.data?.message || 'Error adding to cart.')
    }
  }

  // Handle increase and decrease quantity
  const handleQuantityChange = async (productId, action) => {
    let currentProductId
    const cartProduct = items?.find((item) => item.product.id === productId && item?.variant?.id === selectedVariantItem.id)
    if (cartProduct) currentProductId = cartProduct?.id
    if (!cartProduct) return

    const updatedQuantity = action === 'increase' ? cartProduct.quantity + 1 : cartProduct.quantity - 1

    if (updatedQuantity <= 0) {
      const id = currentProductId
      const response = await removeCartItem(id)
      toast.success(response?.message || `${currentProduct?.name} variant removed from cart.`)
      return
    } // Avoid decreasing below 1

    const payload = { id: currentProductId, quantity: updatedQuantity }

    try {
      const response = await updateCartItemQuantity(payload).unwrap()
      toast.success(response?.message || 'Cart Item updated!')
    } catch (error) {
      toast.error(error?.data?.message || 'Failed to update quantity')
    }
  }

  const variants = singleProduct?.data?.variants || []

  // Handle variants on click of box
  const handleVariantClick = (index) => {
    setSelectedVariant(index)
    setSelectedVariantItem(singleProduct?.data?.variants[index])
  }

  // Handle content rendering for HowToUse dynamically
  const renderContent = (content) => {
    if (content && content.includes('\n')) {
      return (
        <List sx={{ p: 0 }}>
          {content.split('\n').map((item, ind) => (
            <ListItem key={ind} mb={0.5} sx={{ padding: 0, fontSize: 15, color: 'text.primary' }}>
              <ListItemText primary={`${ind + 1}. ${item}`} sx={{ '& .MuiListItemText-primary': { fontWeight: 400, fontSize: 14, m: 0, color: '#42323B' } }} />
            </ListItem>
          ))}
        </List>
      )
    } else {
      return <Typography sx={{ fontSize: 15, color: 'text.primary' }}>{content}</Typography>
    }
  }

  const price = selectedVariantItem ? selectedVariantItem : currentProduct
  const discountedPrice = price['discounted_price']
  const basePrice = price['base_price']

  return (
    <div className="figma mt-5">
      <div className="page-wrapper">
        <div className="page-content">
          <div className="container-fluid p-0 p-md-3">
            {/* Title sec... */}
            <div className="page-title-box">
              <h4 className="page-title" style={{ fontWeight: 600 }}>
                {patient_marketplace('marketPlace')}
              </h4>
            </div>

            {/* Bredcrumb sec... */}
            <ol className="breadcrumb justify-content-end pr-0">
              <li className="breadcrumb-item">
                <Link to={'..'}>{patient_marketplace('marketplace')}</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={'../products'}>{patient_common('product')}</Link>
              </li>
            </ol>

            {isLoading ? (
              <CardLongItem>
                <div className="d-flex justify-content-center">
                  <RingLoading size={200} />
                </div>
              </CardLongItem>
            ) : (
              <>
                {/* About Products sec... */}
                <div class="row">
                  {/* Product left front intro */}
                  <div class="col-12 col-xl-8" style={{ padding: '0 10px' }}>
                    <div className="card mb-3">
                      <div className="card-body p-2 p-md-3">
                        <div className="col-lg-12 row p-0 m-0">
                          {/* Product Images */}
                          <div className="col-12 col-md-5 p-0 d-flex">
                            <div className="d-flex flex-column" style={{ gap: 6 }}>
                              {currentProduct?.images?.map((img, ind) => (
                                <div className="item-img-bg-block" style={{ padding: 4 }}>
                                  <img
                                    src={img?.image_url || TestImage}
                                    alt="product-image"
                                    key={ind}
                                    style={{ maxWidth: 70, height: 70, borderRadius: 8, cursor: 'pointer' }}
                                    onClick={() => setIsPreviewOpen(true)}
                                  />
                                </div>
                              ))}
                            </div>
                            <div className="card item-img-bg-block" style={{ margin: '0 10px', borderRadius: 8 }}>
                              <img
                                src={currentProduct?.images?.length > 0 ? currentProduct.images?.find((img) => img?.is_featured === 1)?.image_url : TestImage}
                                alt="product-image"
                                className="card-img-top"
                                style={{ borderRadius: 8, height: '100%', maxHeight: 220, cursor: 'pointer' }}
                                onClick={() => setIsPreviewOpen(true)}
                              />
                            </div>
                          </div>

                          {/* Product Title and prices and ratings */}
                          <Stack gap={3} className="col-12 col-md-7 justify-content-between">
                            <div>
                              <Card.Title style={{ fontSize: 20, fontWeight: 600 }}>{currentProduct?.name}</Card.Title>
                              <div>
                                <Link style={{ color: '#303e67', textDecoration: 'underline' }} to={'/patient/marketplace/products'}>
                                  {currentProduct?.brand?.name}
                                </Link>
                                <span className="rounded-circle d-inline-block mx-2" style={{ width: 3, height: 3, backgroundColor: '#D9D9D9', padding: 4 }} />
                                <span style={{ fontWeight: 500 }}>
                                  {currentProduct?.quantity} {currentProduct?.dosage_form?.name}
                                </span>
                                {/* {variants?.length > 0 && (
                                  <>
                                    <span className="rounded-circle d-inline-block mx-2" style={{ width: 3, height: 3, backgroundColor: '#D9D9D9', padding: 4 }} />
                                    <span style={{ fontWeight: 500 }}>{variants[0]?.title}</span>
                                  </>
                                )} */}
                              </div>

                              {/* Ratings */}
                              <ProductCardRatings avegRatings={currentProduct?.average_rating || 0} totalRatings={currentProduct?.total_ratings} />
                            </div>

                            {/* Selected stock sec... */}
                            <div>
                              <h6 className="page-title m-0" style={{ fontWeight: 500 }}>
                                {patient_marketplace('selected_size')}:{' '}
                                <span className="ml-2" style={{ fontWeight: 700 }}>
                                  {(currentProduct?.variants?.length ? currentProduct?.variants[selectedVariant]?.title : currentProduct?.name) || '-'}
                                </span>
                              </h6>

                              <div className="mt-3 d-flex" style={{ gap: 8, overflowX: 'auto' }}>
                                {variants?.length > 0 ? (
                                  variants?.map((item, ind) => (
                                    <Stack
                                      gap={1}
                                      className="border p-2 px-3 text-center"
                                      key={ind}
                                      borderRadius={2}
                                      backgroundColor={ind === selectedVariant ? '#42323B' : 'transparent'}
                                      color={ind === selectedVariant ? '#fff' : 'initial'}
                                      style={{ width: 'auto', cursor: 'pointer' }}
                                      onClick={() => handleVariantClick(ind)}
                                    >
                                      <span style={{ fontWeight: 600 }}>{item?.title}</span>
                                      <Divider sx={{ my: 0.5, borderColor: ind === selectedVariant ? '#fff' : '#0000001f' }} />
                                      <Stack gap={0.5}>
                                        <span style={{ fontWeight: 500 }}>${item.discounted_price ? item.discounted_price : item.base_price}</span>
                                        <span style={{ fontSize: 11, color: ind === selectedVariant ? '#fff' : '#008100', fontWeight: 500 }}>
                                          {item.quantity > 0 ? patient_marketplace('in_stock') : patient_marketplace('out_of_stock')}
                                        </span>
                                      </Stack>
                                    </Stack>
                                  ))
                                ) : (
                                  <Stack
                                    className="border p-2 px-3 text-center"
                                    style={{
                                      gap: 3,
                                      borderRadius: 6,
                                      width: 'auto',
                                      cursor: 'pointer',
                                      backgroundColor: '#42323B',
                                      color: '#fff',
                                    }}
                                  >
                                    <span style={{ fontWeight: 600 }}>{currentProduct?.name}</span>
                                    <Divider sx={{ my: 0.5, borderColor: '#fff' }} />
                                    <span style={{ fontWeight: 600 }}>${currentProduct.discounted_price ? currentProduct.discounted_price : currentProduct.base_price}</span>
                                    <span style={{ fontSize: 10, color: '#fff', fontWeight: 500 }}>
                                      {currentProduct.quantity > 0 ? patient_marketplace('in_stock') : patient_marketplace('out_of_stock')}
                                    </span>
                                  </Stack>
                                )}
                              </div>
                            </div>
                          </Stack>
                        </div>
                      </div>
                    </div>

                    {/* Products Tab wise details */}
                    <SingleProductTabs currentProduct={currentProduct} productRatings={productRatings} searchReviewText={searchReviewText} setSearchReviewText={setSearchReviewText} />
                  </div>

                  {/* Product Right top price/discount details */}
                  <div class="col-12 col-xl-4" style={{ padding: '0 10px' }}>
                    <div className="card mb-3">
                      <div className="card-body p-2 p-md-3">
                        <div className="col-lg-12 p-0">
                          {/* Amount display */}
                          <div>
                            {(variants?.length ? discountedPrice : currentProduct?.discounted_price) ? (
                              <div className="d-flex align-items-center">
                                <span className="mr-2" style={{ fontSize: 24, fontWeight: 600 }}>
                                  ${variants?.length ? discountedPrice : currentProduct?.discounted_price}
                                </span>
                                <BasePriceIfHasDiscount basePrice={variants?.length ? basePrice : currentProduct?.base_price} />
                              </div>
                            ) : (
                              <span className="mr-2" style={{ fontSize: 24, fontWeight: 600 }}>
                                ${variants?.length ? basePrice : currentProduct?.base_price}
                              </span>
                            )}

                            {(variants?.length ? discountedPrice : currentProduct?.discounted_price) && (
                              <DiscountedButton
                                basePrice={variants?.length ? basePrice : currentProduct?.base_price}
                                discountedPrice={variants?.length ? discountedPrice : currentProduct?.discounted_price}
                              />
                            )}
                          </div>

                          {/* cart items... */}
                          <div className="mt-4 text-center d-flex flex-column">
                            <Stack gap={1.5} textAlign="left" direction="row" alignItems="center">
                              <span>
                                <FaCircle style={{ width: 8, height: 8, color: '#D9D9D9' }} />
                              </span>
                              <span>{patient_marketplace('product_cannot_be_returned_for_a_refund_or_exchange')}</span>
                            </Stack>

                            <div style={{ marginTop: currentProduct.discounted_price ? (activeProducts.includes(currentProduct.id) ? 2 : 46) : activeProducts.includes(currentProduct.id) ? 24 : 68 }}>
                              {/* Add to cart Reusable Button */}
                              <AddToCartButton
                                activeProducts={activeProducts}
                                product={currentProduct}
                                cartQuantity={matchedcartItemQuantity}
                                onAddToCart={handleAddToCart}
                                onQuantityChange={handleQuantityChange}
                                sx={{ margin: '0 auto', maxWidth: 185 }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* About Product Uses and instructions */}
                    {filteredSidePanel.length ? (
                      <div class="col-12 p-0">
                        <div className="card mb-3">
                          <div className="card-body p-2 p-md-3">
                            <div className="col-lg-12 p-0">
                              <Stack gap={2}>
                                <List sx={{ width: '100%', p: 0 }}>
                                  {filteredSidePanel.map((tab, index) => (
                                    <ListItemWithIcon key={index} title={tab.label} content={renderContent(tab.value)} />
                                  ))}
                                </List>
                              </Stack>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </>
            )}
            {activeProducts.includes(currentProduct.id) && <ViewCartRedierctBox cartData={cartItems?.data} />}

            {isPreviewOpen && <ProductImagePreviewModel isPreviewOpen={isPreviewOpen} handlePreviewClose={() => setIsPreviewOpen(false)} currentProduct={currentProduct} />}
          </div>
        </div>
      </div>
    </div>
  )
}
