import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CustomTable from '../../wallet/CustonTable'
import { useLazyGetServiceListQuery } from 'v2/src/lib/services/marketplace/nHServiceApi'

function ServicesN({ limit }) {
  const { t: provider_services } = useTranslation('provider_services')
  const navigate = useNavigate()

  const [practiceList, setPracticeList] = useState([])
  const [page, setPage] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  const transactionColumn = [
    { id: 'id', label: provider_services('id') },
    { id: 'name', label: provider_services('practice_name') },
    { id: 'booking_availability', label: provider_services('booking_availability') },
    { id: 'amount', label: provider_services('price_rate') },
  ]

  const [getServiceList] = useLazyGetServiceListQuery()

  const getService = async (page) => {
    try {
      const servicesData = await getServiceList({ page }).unwrap()

      if (servicesData) {
        setPracticeList(servicesData?.data)
        setRowPerPage(servicesData?.meta?.per_page)
        setTotal(servicesData?.meta?.total)
      }
    } catch (error) {
      console.error('Error fetching services:', error)
    }
  }

  useEffect(() => {
    getService(page)
  }, [page])

  const handlePageChange = (number) => {
    setPage(number)
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="page-title-box">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="page-title">{provider_services('services')}</h4>
              <ol className="breadcrumb">
                <Link to="manage/new">
                  <button type="button" className="btn btn-success waves-effect waves-light">
                    {provider_services('new_service')}
                  </button>
                </Link>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white p-2">
        <CustomTable
          columns={transactionColumn}
          rows={practiceList}
          total={total}
          perPage={rowPerPage}
          page={page}
          setPage={handlePageChange} // Pass the handler for page change
          isClickableRow
          handleRowClick={(id) => navigate(`/provider/services/manage/update/${id}`)}
        />
      </div>
    </div>
  )
}

export default ServicesN
