import React from 'react'
import Footer from 'components/Footer'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Carousel, Col, Image, Row } from 'react-bootstrap'
import ProductCard from 'v2/src/components/marketplaceProducts/ProductCard'
import { constant } from './products/productConstant'
import { useNavigate } from 'react-router-dom'
import { useGetCategoriesQuery } from 'v2/src/lib/services/marketplace/nHProductsApi'
import RingLoading from 'components/lottie/RingLoading'
import { Box, Button, Stack, useMediaQuery, useTheme } from '@mui/material'
import CarouselImage1 from 'assets/images/marketplace/banner-1.png'
import CarouselImage2 from 'assets/images/marketplace/banner-2.png'
import CarouselImage3 from 'assets/images/marketplace/banner-3.png'
import CarouselImage4 from 'assets/images/marketplace/banner-4.png'
import './marketplace.css'

export const StyleWrapper = styled.div`
  .optionListContainer {
    position: sticky;
  }
`
// const { carouselItems, healthServices } = constant
const { healthServices } = constant

export default function Marketplace() {
  const navigate = useNavigate()
  const { t: patient_marketplace } = useTranslation('patient_marketplace')
  const theme = useTheme()

  const isUptoLarge = useMediaQuery(theme.breakpoints.between('md', 'lg'))
  const isUptoExtraLarge = useMediaQuery(theme.breakpoints.between('lg', 'xl'))

  const { data: categoriesData, isLoading } = useGetCategoriesQuery({}, { refetchOnMountOrArgChange: true })

  const handleNavigation = (redirectTo) => {
    navigate(redirectTo)
  }

  const handleCategoryClick = (categoryId, categoryname) => {
    navigate(`products?categoryId=${categoryId}&categoryname=${encodeURIComponent(categoryname)}`)
  }

  const carouselItems = [
    {
      img: CarouselImage1,
      bottom: '34%',
      left: '10%',
      transform: 'translate(-50%, 90%)',
      padding: isUptoLarge ? '9px 27px' : isUptoExtraLarge ? '9px 35px' : '9px 35px',
      fontSize: isUptoLarge ? '10px' : isUptoExtraLarge ? '14px' : '19px',
      btnText: 'Book Virtual Visit',
      link: '/virtualvisit',
    },
    {
      img: CarouselImage2,
      bottom: '49%',
      left: '10%',
      transform: 'translate(-50%, 90%)',
      padding: isUptoLarge ? '8px 27px' : isUptoExtraLarge ? '8px 27px' : '8px 36px',
      fontSize: isUptoLarge ? '10px' : isUptoExtraLarge ? '15px' : '20px',
      btnText: 'Shop Medicines',
      link: '/patient/marketplace/products',
    },
    {
      img: CarouselImage3,
      bottom: isUptoLarge ? '28%' : isUptoExtraLarge ? '28%' : '27%',
      left: isUptoLarge ? '3%' : isUptoExtraLarge ? '4%' : '4%',
      transform: isUptoExtraLarge ? 'translate(-8px,96%)' : 'translate(0, 90%)',
      padding: isUptoLarge ? '6px 33px' : isUptoExtraLarge ? '7px 36px' : '7px 40px',
      fontSize: isUptoLarge ? '12px' : isUptoExtraLarge ? '15px' : '19px',
      btnText: 'Book Your In-Clinic Appointment Now!',
      link: '/patient/marketplace/services',
    },
    {
      img: CarouselImage4,
      bottom: '34%',
      left: '7%',
      transform: 'translate(-45%, 90%)',
      padding: isUptoLarge ? '8px 22px' : isUptoExtraLarge ? '8px 32px' : '8px 32px',
      fontSize: isUptoLarge ? '12px' : isUptoExtraLarge ? '15px' : '22px',
      btnText: 'Buy Now',
      link: '/patient/marketplace/products',
    },
  ]

  return (
    <div className="figma mt-5">
      <div className="page-wrapper">
        <div className="page-content">
          <Stack gap={2} className="p-0 p-md-3">
            {/* Home carousel */}
            <div className="row border">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="page-title" style={{ fontWeight: 600 }}>
                    {patient_marketplace('marketPlace')}
                  </h4>
                </div>
                <div>
                  <Carousel controls={false} interval={3000}>
                    {carouselItems?.map((item, index) => (
                      <Carousel.Item key={index}>
                        <div style={{ position: 'relative' }}>
                          <Image src={item.img} alt={`image-${index}`} style={{ width: '100%', maxHeight: 'fit-content', borderRadius: 8 }} fluid text={`${index}-slide`} />
                          <Button
                            variant="primary"
                            sx={{ textTransform: 'capitalize' }}
                            style={{
                              position: 'absolute',
                              bottom: item.bottom,
                              left: item.left,
                              transform: item.transform,
                              zIndex: 1,
                              backgroundColor: ' rgb(0, 129, 0)',
                              border: 'none',
                              color: 'rgb(255, 255, 255)',
                              borderRadius: ' 50px',
                              padding: item.padding,
                              fontSize: item.fontSize,
                            }}
                            onClick={() => navigate(item.link)}
                          >
                            {item.btnText}
                          </Button>
                        </div>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>

            {/* Book Health Services Part */}
            <Box mt={3}>
              <h6 className="m-0 page-title py-1" style={{ fontWeight: 600, fontSize: 15 }}>
                {patient_marketplace('bookHealthServices')}
              </h6>
              <Row>
                {healthServices.map((item, index) => (
                  <Col xs={12} md={6} style={{ padding: 12 }} key={index}>
                    <ProductCard key={index} label={patient_marketplace(item.label)} icon={item.icon} onClick={() => handleNavigation(item.redirectTo)} />
                  </Col>
                ))}
              </Row>
            </Box>

            {/* Shop By category Part */}
            <Box>
              <h6 className="m-0 page-title py-1" style={{ fontWeight: 600, fontSize: 15 }}>
                {patient_marketplace('shop_by_category')}
              </h6>
              <Row>
                {isLoading ? (
                  <div className="d-flex justify-content-center w-100">
                    <RingLoading size={100} />
                  </div>
                ) : (
                  categoriesData?.data?.map((item, index) => (
                    <Col xs={12} md={6} lg={4} style={{ padding: 12 }} key={index}>
                      <ProductCard key={index} label={item.name} imgSrc={item.image_url} onClick={() => handleCategoryClick(item.id, item.name)} />
                    </Col>
                  ))
                )}
              </Row>
            </Box>
          </Stack>
          <Footer />
        </div>
      </div>
    </div>
  )
}
