import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import MarketplaceReviewRatingTab from '../product-tabs/ProductsAllTabs'
import { constant } from '../productConstant'

const { TAB } = constant

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

export default function SingleProductTabs({ currentProduct, productRatings, searchReviewText, setSearchReviewText }) {
  const theme = useTheme()
  const [value, setValue] = useState(0)
  const { t: patient_marketplace } = useTranslation('patient_marketplace')
  const [filteredTabs, setFilteredTabs] = useState([]) // Set For fresh filter tab data

  // Filter the tabs once and store in the state
  useEffect(() => {
    if (currentProduct?.details?.length) {
      const filtered = currentProduct.details.filter((tab) => tab.display_type === TAB)
      filtered.push({ label: patient_marketplace('ratings_reviews') })
      setFilteredTabs(filtered)
    }
  }, [currentProduct])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  // Function to render content dynamically
  const renderContent = (content) => {
    // If the content contains newlines, treat it as a list
    if (content && content.includes('\n')) {
      return (
        <>
          <List>
            {content.split('\n').map((line, idx) => (
              <ListItem key={idx} sx={{ p: 0, paddingLeft: 2, gap: 1.5 }}>
                <ListItemText primary={line} sx={{ '& .MuiListItemText-primary': { fontWeight: 400 } }} />
              </ListItem>
            ))}
          </List>
        </>
      )
    }
    // If no newlines, treat it as a paragraph
    return (
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        {content}
      </Typography>
    )
  }

  return (
    <div class="col-12 p-0">
      <div className="card mb-3">
        <div className="card-body p-2 p-md-3">
          <div className="col-lg-12 p-0">
            <Box>
              {filteredTabs?.length ? (
                <AppBar position="static" sx={{ boxShadow: 'none', backgroundColor: 'transparent' }}>
                  <Tabs
                    value={value}
                    sx={{ backgroundColor: '#f1f5fa', padding: 1, borderRadius: 1.5, '& .MuiTabs-flexContainer': { overflowX: 'auto', gap: 1 } }}
                    onChange={handleChange}
                    indicatorColor="none"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs"
                  >
                    {filteredTabs.map((tab, index) => (
                      <Tab
                        key={index}
                        label={tab.label}
                        sx={{
                          minHeight: 0,
                          color: '#008100',
                          textTransform: 'capitalize',
                          borderRadius: 1,
                          transition: 'all 0.3s',
                          '&:hover': { backgroundColor: '#008100', color: '#fff' },
                          '&.MuiTab-root.Mui-selected': { color: '#fff', backgroundColor: '#008100' },
                        }}
                        {...a11yProps(index)}
                      />
                    ))}
                  </Tabs>
                </AppBar>
              ) : (
                <AppBar position="static" sx={{ boxShadow: 'none', backgroundColor: 'transparent' }}>
                  <Tabs
                    value={value}
                    sx={{ backgroundColor: '#f1f5fa', padding: 1, borderRadius: 1.5, '& .MuiTabs-flexContainer': { overflowX: 'auto', gap: 1 } }}
                    onChange={handleChange}
                    indicatorColor="none"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs"
                  >
                    <Tab
                      label={patient_marketplace('ratings_reviews')}
                      sx={{
                        minHeight: 0,
                        color: '#008100',
                        textTransform: 'capitalize',
                        borderRadius: 1,
                        transition: 'all 0.3s',
                        '&:hover': { backgroundColor: '#008100', color: '#fff' },
                        '&.MuiTab-root.Mui-selected': { color: '#fff', backgroundColor: '#008100' },
                      }}
                    />
                  </Tabs>
                </AppBar>
              )}
              {/* Render TabPanel from filteredTabs state */}
              {filteredTabs?.length ? (
                filteredTabs.map((tab, index) => {
                  return (
                    <TabPanel value={value} index={index} key={index} dir={theme.direction}>
                      {/* Render the content dynamically */}
                      {index === filteredTabs.length - 1 ? (
                        <MarketplaceReviewRatingTab productRatings={productRatings} setSearchReviewText={setSearchReviewText} />
                      ) : (
                        renderContent(tab.value || 'No content available.')
                      )}
                    </TabPanel>
                  )
                })
              ) : (
                <MarketplaceReviewRatingTab productRatings={productRatings} searchReviewText={searchReviewText} setSearchReviewText={setSearchReviewText} />
              )}
            </Box>
          </div>
        </div>
      </div>
    </div>
  )
}
