import React, { useState } from 'react'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import RingLoading from 'components/lottie/RingLoading'

const CartCheckout = ({ onPaymentSuccess, isCheckoutLoading }) => {
  const { t: patient_marketplace } = useTranslation('patient_marketplace')
  const stripe = useStripe()
  const elements = useElements()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const handleSubmit = async () => {
    setLoading(true)
    setError(null)

    if (!stripe || !elements) {
      setLoading(false)
      return
    }

    const cardElement = elements.getElement(CardElement)

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    })

    if (error) {
      setError(error.message)
      setLoading(false)
      return
    }

    if (paymentMethod) {
      onPaymentSuccess(paymentMethod)
      setLoading(false)
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <CardElement className="stripe-card-element custom-stripe-input" />
      <span md="auto">
        <Button
          disabled={!stripe || loading || isCheckoutLoading}
          onClick={handleSubmit}
          sx={{ mt: 2, width: { xs: 'auto', md: 220 }, textTransform: 'capitalize' }}
          style={{
            backgroundColor: '#008100',
            border: 'none',
            color: '#fff',
            borderRadius: '50px',
            padding: isCheckoutLoading || loading ? '0' : '16px 40px',
          }}
        >
          {isCheckoutLoading || loading ? <RingLoading size={57} /> : patient_marketplace('make_payment')}
        </Button>
      </span>
    </div>
  )
}

export default CartCheckout
