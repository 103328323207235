import { useEffect, useState } from 'react'
import Footer from 'components/Footer'
import { Col, Image, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ProductSearchSection from './SearchFilter'
import AllMarketPlaceProductsList from './AllProductList'
import { Typography } from '@mui/material'
import { FaArrowLeftLong } from 'react-icons/fa6'
import ViewCartRedierctBox from 'v2/src/components/common/ViewCartBox'
import { useGetCategoriesQuery, useLazyGetCategoryChildrenQuery, useLazyGetProductsQuery } from 'v2/src/lib/services/marketplace/nHProductsApi'
import RingLoading from 'components/lottie/RingLoading'
import { useGetCartItemsQuery } from 'v2/src/lib/services/marketplace/nHCartApi'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import CustomPagination from 'v2/src/components/CustomPagination'
import CommonCard from 'v2/src/components/layout/CardCommonLayout'
import { constant } from './productConstant'
import { toast } from 'react-toastify'

const { MIN_RANGE, MAX_RANGE } = constant

export default function MarketPlaceProducts() {
  const { t: patient_marketplace } = useTranslation('patient_marketplace')
  const { t: patient_common } = useTranslation('patient_common')

  const location = useLocation()
  const navigate = useNavigate()

  // Using URLSearchParams to parse query parameters
  const queryParams = new URLSearchParams(location.search)
  const categoryId = queryParams.get('categoryId')
  const categoryName = queryParams.get('categoryname')

  // States
  const [brandIds, setBrandIds] = useState([])
  const [productForm, setProductForm] = useState([])
  const [priceRange, setPriceRange] = useState([MIN_RANGE, MAX_RANGE])
  const [selectedRating, setSelectedRating] = useState()
  const [searchTriggered, setSearchTriggered] = useState(false)
  const [iscartBoxDisplay, setIscartBoxDisplay] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [cartData, setCartData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [categoryChildrenDetails, setCategoryChildrenDetails] = useState([])

  // Fetch cart items and categories
  const { data: fetchedCartItems } = useGetCartItemsQuery({ page: 1, per_page: 4 }, { refetchOnMountOrArgChange: true })
  const { data: categoriesData, isLoading: isCategoryLoading } = useGetCategoriesQuery({}, { refetchOnMountOrArgChange: true })

  // Conditionally fetch category children when categoryId is present
  const [getCategoryChildren, { data: categoryChildrenData, isLoading: isCategoryChildrenLoading }] = useLazyGetCategoryChildrenQuery()
  const [getProducts, { data: productsData, error: isGetProductError, isLoading: isProductLoading }] = useLazyGetProductsQuery()

  // Get Children os current category using id from params
  useEffect(() => {
    if (categoryChildrenData?.status) {
      setCategoryChildrenDetails(categoryChildrenData?.data)
    }
  }, [categoryChildrenData])

  useEffect(() => {
    if (fetchedCartItems?.status) {
      setCartData(fetchedCartItems?.data)
    }
  }, [fetchedCartItems])

  useEffect(() => {
    const fetchCategoryChildren = async () => {
      try {
        if (categoryId) await getCategoryChildren({ categoryId }).unwrap()
      } catch (error) {
        toast.error(error?.data?.message)
      }
    }

    fetchCategoryChildren()
  }, [categoryId])

  // Validate coupon text field
  useEffect(() => {
    if (searchText === '' || !searchText) {
      setSearchTriggered((prev) => !prev)
    }
  }, [searchText])

  // Get Products
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const relations = ['images', 'variants', 'dosageForm', 'brand', 'ratings']
        // Initialize the filters object
        const params = new URLSearchParams()
        // Add basic params
        params.append('page', currentPage)
        params.append('per_page', 8)
        params.append('status', 'active')

        // Conditionally append other params
        if (searchText) params.append('name', searchText)
        if (productForm) productForm.forEach((item) => params.append('dosage_form_id[]', item))
        if (brandIds) brandIds.forEach((item) => params.append('brand_id[]', item))
        if (selectedRating !== undefined) params.append('rating', selectedRating === 0 ? '0' : selectedRating)
        const [minPrice, maxPrice] = priceRange
        if (!(minPrice === MIN_RANGE && maxPrice === MAX_RANGE)) {
          if (minPrice >= MIN_RANGE) params.append('min_price', minPrice)
          if (maxPrice < MAX_RANGE) params.append('max_price', maxPrice)
        }
        if (categoryId) {
          params.append('collection_id', categoryId)
        }

        if (relations.length) {
          relations.forEach((relations) => params.append('relations[]', encodeURIComponent(relations)))
        }

        await getProducts(params).unwrap()
      } catch (e) {
        console.log('Error: ', e)
      }
    }

    // Call the function
    fetchProducts()
  }, [currentPage, categoryId, searchTriggered])

  useEffect(() => {
    if (isGetProductError) toast.error(isGetProductError?.data?.message)
  }, [isGetProductError])

  // handling Brand Name in filer func.
  // const handleBrandSelection = (brand) => {
  const handleBrandSelection = (brandId) => {
    setBrandIds(
      (prev) => (prev.includes(brandId) ? prev.filter((r) => r !== brandId) : [...prev, brandId]) // Toggle selection
    )
  }

  // handling Product Form in filer func.
  const handleProductFormSelection = (productFormId) => {
    setProductForm(
      (prev) => (prev.includes(productFormId) ? prev.filter((r) => r !== productFormId) : [...prev, productFormId]) // Toggle selection
    )
  }

  // handling ratings in filer func.
  const handleRatingSelect = (rating) => {
    if (selectedRating === rating) {
      setSelectedRating()
    } else {
      setSelectedRating(rating)
    }
  }

  // set price range in filter
  const handlePriceRangeChange = (event, newValue) => {
    setPriceRange(newValue)
  }

  // pagination for products list
  const handlePageChange = (event, value) => {
    setCurrentPage(value) // Update the page on user interaction
  }

  // Product Showcase
  const handleCategoryClick = (categoryId, categoryName) => {
    queryParams.set('categoryId', categoryId)
    queryParams.set('categoryname', encodeURIComponent(categoryName))
    navigate(location.pathname + '?' + queryParams.toString())
  }

  const handleShowAllProduct = () => {
    queryParams.delete('categoryId') // This removes the 'categoryId' parameter
    queryParams.delete('categoryname')
    navigate(location.pathname + '?' + queryParams.toString())
  }

  return (
    <div className="figma mt-5">
      <div className="page-wrapper">
        <div className="page-content">
          <div className="container-fluid p-0 p-md-3">
            {/* Title sec... */}
            <div className="page-title-box">
              <h4 className="page-title" style={{ fontWeight: 600 }}>
                {patient_marketplace('marketPlace')}
              </h4>
            </div>

            {/* Bredcrumb sec... */}
            <ol className="breadcrumb justify-content-end px-0 py-2">
              <li className="breadcrumb-item" style={{ fontSize: 15 }}>
                <Link to={'..'} style={{ color: 'inherit' }}>
                  {patient_marketplace('marketplace')}
                </Link>
              </li>
              <li className="breadcrumb-item" style={{ fontSize: 15, color: '#53b143' }}>
                {patient_common('products')}
              </li>
            </ol>

            {/* Search and Filters sec... */}
            <CommonCard>
              <div className="col-lg-12 p-0">
                <h5 className="mt-1 mb-3" style={{ fontSize: 16, fontWeight: 600 }}>
                  {patient_marketplace('search_and_filter')}
                </h5>
                <ProductSearchSection
                  priceRange={priceRange}
                  setPriceRange={setPriceRange}
                  handlePriceRangeChange={handlePriceRangeChange}
                  selectedRating={selectedRating}
                  setSelectedRating={setSelectedRating}
                  handleRatingSelect={handleRatingSelect}
                  setSearchTriggered={setSearchTriggered}
                  searchText={searchText}
                  setSearchText={setSearchText}
                  brandIds={brandIds}
                  productForm={productForm}
                  setBrandIds={setBrandIds}
                  setProductForm={setProductForm}
                  handleBrandSelection={handleBrandSelection}
                  handleProductFormSelection={handleProductFormSelection}
                />
              </div>
            </CommonCard>

            {/* Shop by category sec... */}
            {categoryId ? (
              categoryChildrenDetails?.length ? (
                <CommonCard>
                  <div className="col-lg-12 p-0">
                    <h5 className="mt-1 mb-3" style={{ fontSize: 16, fontWeight: 600 }}>
                      {patient_marketplace('shop_by_category')}
                    </h5>

                    <Row className="flex-nowrap" style={{ overflowX: 'auto', paddingBottom: 5, scrollbarColor: '#f1f5fa transparent', scrollbarWidth: 'thin' }}>
                      {isCategoryChildrenLoading ? (
                        <div className="d-flex justify-content-center w-100">
                          <RingLoading size={150} />
                        </div>
                      ) : (
                        categoryChildrenDetails?.map((item, index) => (
                          <Col style={{ cursor: 'pointer', gap: 16 }} className="d-flex flex-column" key={index} xs={12} sm={6} md={4} lg={2} onClick={() => handleCategoryClick(item.id, item.name)}>
                            <div className="item-img-bg-block">
                              <Image src={item.image_url} fluid style={{ borderRadius: 8, maxWidth: 165, maxHeight: 100 }} className="mb-2" />
                            </div>
                            <Typography fontFamily="inherit" className="text-center" fontSize={13.5}>
                              {item.name}
                            </Typography>
                          </Col>
                        ))
                      )}
                    </Row>
                  </div>
                </CommonCard>
              ) : null
            ) : (
              <CommonCard>
                <div className="col-lg-12 p-0">
                  <h5 className="mt-1 mb-3" style={{ fontSize: 16, fontWeight: 600 }}>
                    {patient_marketplace('shop_by_category')}
                  </h5>

                  <Row className="flex-nowrap" style={{ overflowX: 'auto', paddingBottom: 5, scrollbarColor: '#f1f5fa transparent', scrollbarWidth: 'thin' }}>
                    {isCategoryLoading ? (
                      <div className="d-flex justify-content-center w-100">
                        <RingLoading size={150} />
                      </div>
                    ) : (
                      categoriesData?.data?.map((item, index) => (
                        <Col style={{ cursor: 'pointer', gap: 16 }} className="d-flex flex-column" key={index} xs={12} sm={6} md={4} lg={2} onClick={() => handleCategoryClick(item.id, item.name)}>
                          <div className="item-img-bg-block">
                            <Image src={item.image_url} fluid style={{ borderRadius: 8, maxWidth: 165, maxHeight: 100 }} className="mb-2" />
                          </div>
                          <Typography fontFamily="inherit" className="text-center" fontSize={13.5}>
                            {item.name}
                          </Typography>
                        </Col>
                      ))
                    )}
                  </Row>
                </div>
              </CommonCard>
            )}

            <div>
              {/* All Products List sec... */}
              <CommonCard>
                <div className="col-lg-12 p-0">
                  {isProductLoading ? (
                    <div className="d-flex justify-content-center w-100">
                      <RingLoading size={150} />
                    </div>
                  ) : (
                    <>
                      <h5 className="mt-1 mb-4 d-flex justify-content-between" style={{ fontSize: 16, fontWeight: 600 }}>
                        {categoryId
                          ? patient_marketplace('showing_result_of_products', { current_product_title: decodeURIComponent(categoryName), total_products: productsData?.meta?.total || 0 })
                          : patient_marketplace('all_products', { total_products: productsData?.meta?.total || 0 })}

                        {categoryId && (
                          <div className="d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={handleShowAllProduct}>
                            <FaArrowLeftLong className="mr-2" /> back
                          </div>
                        )}
                      </h5>
                      <AllMarketPlaceProductsList productList={productsData?.data} productListMessage={productsData?.message} setIscartBoxDisplay={setIscartBoxDisplay} cartData={cartData} />
                    </>
                  )}
                </div>
              </CommonCard>

              {/* Pagination for All products */}
              {productsData?.data?.length > 0 && <CustomPagination currentPage={currentPage} count={productsData?.meta?.last_page} handlePageChange={handlePageChange} />}
            </div>
            {iscartBoxDisplay && <ViewCartRedierctBox cartData={cartData} />}
          </div>
          <Footer />
        </div>
      </div>
    </div>
  )
}
