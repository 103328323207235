import { nHApi } from 'v2/src/lib/services/public/nHApi'

export const nHServiceApi = nHApi.injectEndpoints({
  endpoints: (builder) => ({
    postService: builder.mutation({
      query: ({ formData }) => {
        return { url: '/service', method: 'POST', body: formData }
      },
      invalidatesTags: ['services'],
    }),
    updateService: builder.mutation({
      query: ({ formData, id }) => {
        return { url: `/service/${id}`, method: 'POST', body: formData }
      },
    }),

    getServiceList: builder.query({
      query: ({ params }) => {
        return { url: `/services?${params.toString()}` }
      },
      providesTags: ['services'],
    }),

    getSingleService: builder.query({
      query: ({ id }) => {
        const relations = ['practice']
        const relationsParam = relations.map((relation) => `relations[]=${encodeURIComponent(relation)}`).join('&')
        return { url: `/service/${id}?${relationsParam}` }
      },
    }),
  }),
})

export const { usePostServiceMutation, useUpdateServiceMutation, useLazyGetServiceListQuery, useLazyGetSingleServiceQuery } = nHServiceApi
