// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Marketplace Landing styles */

.carousel .carousel-item img {
  object-fit: cover !important;
}

.carousel .carousel-indicators {
  bottom: -30px;
}

.carousel .carousel-indicators button {
  width: 10px;
  height: 10px;
  background-color: #d9d9d9;
  border-radius: 50%;
  margin: 5px;
  padding: 5px;
  border: 0;
}

.carousel .carousel-indicators button.active {
  background-color: green;
}`, "",{"version":3,"sources":["webpack://./src/v2/src/pages/marketPlace/marketplace.css"],"names":[],"mappings":"AAAA,+BAA+B;;AAE/B;EACE,4BAA4B;AAC9B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,SAAS;AACX;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":["/* Marketplace Landing styles */\n\n.carousel .carousel-item img {\n  object-fit: cover !important;\n}\n\n.carousel .carousel-indicators {\n  bottom: -30px;\n}\n\n.carousel .carousel-indicators button {\n  width: 10px;\n  height: 10px;\n  background-color: #d9d9d9;\n  border-radius: 50%;\n  margin: 5px;\n  padding: 5px;\n  border: 0;\n}\n\n.carousel .carousel-indicators button.active {\n  background-color: green;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
